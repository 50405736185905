import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormHeader from "../../components/FormHeader/FormHeader";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import {
  isAuthenticated,
  dispatchFirebaseUid,
  dispatchAuth,
  jwtAuth,
  saveToken
} from "../../store/actions/actions";
import axios from "axios";
import {
  hostApi,
  firebasePassword,
  COLLABORATOR,
  IDEATOR,
  timeOut
} from "../../constants";
import fire from "../../constants";
import { gotoMySPRXS } from "../../authentication/authentication";
import SignupProgressButton from "../../components/ProgressButton/SignupProgressButton";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import FacebookIcon from "@material-ui/icons/Facebook";
import "firebase/auth";
import "firebase/database";
import * as firebase from "firebase/app";
import "./LoginPage.css";
import { FullscreenExit } from "@material-ui/icons";

const styles = theme => ({
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  buttonProgress: {
    color: "#b23820",
    position: "absolute",
    top: "50%",
    left: "90%",
    marginTop: -12,
    marginLeft: -12
  },
  leftIcon: {
    marginRight: theme.spacing.unit * 1
  },
  rightIcon: {
    marginLeft: theme.spacing.unit * 1
  },
  button: {
    margin: theme.spacing.unit * 1,
    boxSizing: "border-box",
    marginLeft: "3%"
  },
  iconSmall: {
    fontSize: 20,
    marginRight: theme.spacing.unit * 1
  },
  facebook: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: " 4% auto",
    textAlign: "center",
    whiteSpace: "nowrap",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 4px 0px" // 3px 4px 3px -1px;
  }
});

function validateField(email) {
  if (email === "" || email === undefined) {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}

function validateFields(email, password) {
  if (email === "" || email === undefined || password === "") {
    return true; /* disable the button */
  } else {
    return false; /* enable the button */
  }
}
const emailExist =
  "Cannot create new account, as account already exists, please login using Facebook or SPRXS login";
const helpMessageEnterEmail =
  "Please enter the email address this account is registered to.";
const helpMessageFirstTimeLogin =
  "If you do not have a password please check your Inbox / Spam for your SPRXS account activation password.";

const errorNotValidEmail = "*Please enter a valid email address";
const errorInvalid = "Invalid Email or Password";
const successEmailSent = "A Password Reset email has been sent to :";
const errorNetwork = "Login Timeout. Please try again.";

class LoginPage extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
    onClick: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      lkp_email: cookies.get("emailAddress" || ""),
      emailValidation: false,
      //lkp_email: "",
      password: "",
      isLoading: true,
      success: false,
      loading: false,
      isLoading2: true,
      success2: false,
      loading2: false,
      forgotPassword: false,
      formTitle: "Login to SPRXS",
      helpMessage: "",
      errorMessage: "",
      isSignedIn: false,
      linkText: "Cancel",
      isDisabled: true,
      focusEmail: true,
      focusPassword: false,
      showPassword: false,
      profileId: ""
    };
  }

  componentDidMount() {
    if (this.state.lkp_email !== undefined) {
      this.setState({
        emailValidation: false,
        focusEmail: false,
        focusPassword: true,
        helpMessage: helpMessageFirstTimeLogin
      });
    } else {
      this.setState({
        emailValidation: true,
        focusEmail: true,
        focusPassword: false
      });
    }
  }

  componentWillMount() {
    //this.setJourney("CREATE");
    this.unregisterAuthObserver = fire.auth().onAuthStateChanged(user => {
      if (user) {
        console.log(user);
        //console.log(user.providerData[0].email);
        // User is signed in.
        this.setState({ authUser: true, isSignedIn: !!user });
        // this.setState({ errorMessage: emailExist });
      }
    });
  }

  navigateToMySPRXS = (profileId, email, firstName, lastName) => {
    //this.handleCloseReg();
    window.sessionStorage.setItem(
      "userInfo",
      JSON.stringify({
        profileId: profileId,
        email: email,
        firstName: firstName,
        lastName: lastName
      })
    );
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.unregisterAuthObserver();
  }

  handleEmail = e => {
    const emailValid = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    if (e.target.value.length === 0) {
      this.setState({ isDisabled: true, lkp_email: undefined });
    } else {
      this.setState({
        emailValidation: emailValid === null ? true : false
      });
      this.setState({ lkp_email: e.target.value.trim() });
    }
    if (emailValid) {
      this.setState({
        isDisabled: validateFields(e.target.value.trim(), this.state.password)
      });
    }
    if (emailValid && this.state.forgotPassword) {
      this.setState({ isDisabled: validateField(e.target.value.trim()) });
    }
    if (!emailValid) {
      this.setState({ isDisabled: true });
    }
  };

  handlePassword = e => {
    this.setState({ password: e.target.value });

    if (e.target.value.length === 0) {
      this.setState({ isDisabled: true });
    } else {
      this.setState({
        isDisabled: validateFields(this.state.lkp_email, e.target.value)
      });
      //activate
    }
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleFbLogin = () => {
    let fbcallback =
      "http://www.facebook.com/dialog/oauth?client_id=485447652179085&redirect_uri=https://www.fifth9-uat.com/facebook&scope=email";
    window.location = fbcallback;
  };

  handleGoogleLogin2 = e => {
    let provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    e.preventDefault();
    let firebaseSuccess = false;
    //const that = this;
    const { cookies, saveToken } = this.props;

    // Validation
    if (!firebaseSuccess) {
      // console.log("ERROR WITH EMAIL VALIDATION");
      this.setState({ errorMessage: errorNotValidEmail });
    } else {
      // console.log("EMAIL VALIDATION FINE MOVE TO AXIOS")
      this.setState({ isLoading: true });
      this.setState({ loading: true });

      this.timer = setTimeout(() => {
        this.setState({ errorMessage: errorNetwork, loading: false });
      }, 30000);

      axios
        .post(`${hostApi}/loginProfile_V2`, {
          email_add: this.state.lkp_email,
          passwrd: "Acinorev5"
        })
        .then(response => {
          window.sessionStorage.setItem(
            "userInfo",
            JSON.stringify({
              profile_id: response.data.id,
              email: response.data.login_email,
              firstName: response.data.login_firstname,
              lastName: response.data.login_surname
            })
          );

          //dispatch the redux action to save Jason Web Token
          if (!cookies.get("bearerToken")) {
            saveToken(cookies, response.data.token);
          }
          cookies.set("profile_id", response.data.id, {
            path: "/",
            maxAge: timeOut
          });

          this.props.dispatchAuth(response.data.profile_type, response.data.id);

          this.setState({ isLoading: false });

          fire
            .auth()
            .signInWithPopup(provider)
            .then(u => {
              let user = fire.auth().currentUser;
              cookies.set("fireId", user.uid, { path: "/", maxAge: timeOut }); //will expire in 1hr

              this.props.dispatchFirebaseUid(user.uid);

              firebaseSuccess = true;

              if (
                response.data.login_response === "FAIL" ||
                firebaseSuccess === false
              ) {
                this.setState({ errorMessage: errorInvalid, loading: false });
                clearTimeout(this.timer);
                this.props.history.push(`/`);
              } else {
                clearTimeout(this.timer);
                this.setState({ loading: false, success: true });

                cookies.set("isAuthenticated", true, {
                  path: "/",
                  maxAge: timeOut
                }); //will expire in 1hr
                cookies.set("emailAddress", user.email, {
                  path: "/",
                  maxAge: timeOut
                }); //will expire in 1hr

                this.props.dispatchisAuthenticated(user.email);

                cookies.set("name", response.data.login_firstname, {
                  path: "/",
                  maxAge: timeOut
                });
                cookies.set("last_name", response.data.login_surname, {
                  path: "/",
                  maxAge: timeOut
                });

                var defaultPage = `/dashboard`; // KN 2019.02.03
                this.props.history.push(defaultPage, {
                  data: { firstname: response.data.login_firstname }
                });
              }
            })
            .catch(error => {
              clearTimeout(this.timer);
              this.setState({ errorMessage: errorInvalid });
              this.props.history.push(`/login`);
            });
        })
        .catch(error => {
          console.log("ERROR", error);
          clearTimeout(this.timer);
          this.setState({ errorMessage: errorInvalid, loading: false });
        });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    let firebaseSuccess = false;
    const { cookies, saveToken } = this.props;

    // Validation
    if (this.state.emailValidation || this.state.lkp_email === "") {
      // console.log("ERROR WITH EMAIL VALIDATION");
      this.setState({ errorMessage: errorNotValidEmail });
    } else {
      // console.log("EMAIL VALIDATION FINE MOVE TO AXIOS")
      this.setState({ isLoading: true });
      this.setState({ loading: true });

      this.timer = setTimeout(() => {
        this.setState({ errorMessage: errorNetwork, loading: false });
      }, 30000);

      axios
        .post(`${hostApi}/loginProfile_V2`, {
          email_add: this.state.lkp_email,
          passwrd: this.state.password
        })
        .then(response => {
          window.sessionStorage.setItem(
            "userInfo",
            JSON.stringify({
              profile_id: response.data.id,
              email: response.data.login_email,
              firstName: response.data.login_firstname,
              lastName: response.data.login_surname
            })
          );

          //dispatch the redux action to save Jason Web Token
          if (!cookies.get("bearerToken")) {
            saveToken(cookies, response.data.token);
          }
          cookies.set("profile_id", response.data.id, {
            path: "/",
            maxAge: timeOut
          });

          // if( response.data.profile_type === "2" ){
          //   cookies.set("user_type", COLLABORATOR, { path: "/", maxAge: timeOut }); //will expire in 1hr
          // } else {
          //   cookies.set("user_type", IDEATOR, { path: "/", maxAge: timeOut }); //will expire in 1hr
          // }

          this.props.dispatchAuth(response.data.profile_type, response.data.id);

          this.setState({ isLoading: false });

          fire
            .auth()
            .signInWithEmailAndPassword(this.state.lkp_email, firebasePassword)
            .then(u => {
              let user = fire.auth().currentUser;
              cookies.set("fireId", user.uid, { path: "/", maxAge: timeOut }); //will expire in 1hr

              this.props.dispatchFirebaseUid(user.uid);

              firebaseSuccess = true;

              if (
                response.data.login_response === "FAIL" ||
                firebaseSuccess === false
              ) {
                this.setState({ errorMessage: errorInvalid, loading: false });
                clearTimeout(this.timer);
                this.props.history.push(`/`);
              } else {
                clearTimeout(this.timer);
                this.setState({ loading: false, success: true });

                cookies.set("isAuthenticated", true, {
                  path: "/",
                  maxAge: timeOut
                }); //will expire in 1hr
                cookies.set("emailAddress", this.state.lkp_email, {
                  path: "/",
                  maxAge: timeOut
                }); //will expire in 1hr

                this.props.dispatchisAuthenticated(this.state.lkp_email);

                cookies.set("name", response.data.login_firstname, {
                  path: "/",
                  maxAge: timeOut
                });
                cookies.set("last_name", response.data.login_surname, {
                  path: "/",
                  maxAge: timeOut
                });

                var defaultPage = `/dashboard`; // KN 2019.02.03
                this.props.history.push(defaultPage, {
                  data: { firstname: response.data.login_firstname }
                });
              }
            })
            .catch(error => {
              clearTimeout(this.timer);
              this.setState({ errorMessage: errorInvalid });
              this.props.history.push(`/login`);
            });
        })
        .catch(error => {
          console.log("ERROR", error);
          clearTimeout(this.timer);
          this.setState({ errorMessage: errorInvalid, loading: false });
        });
    }
  };

  // Forget Password Response
  // -----------------------------------------------------------------------------------------------------------------------------------------------
  handleForgot = e => {
    e.preventDefault();
    this.setState({
      forgotPassword: true,
      formTitle: "Forgot your password?",
      helpMessage: helpMessageEnterEmail,
      errorMessage: ""
    });

    if (this.state.lkp_email !== undefined) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ focusEmail: true, isDisabled: true });
    }
  };

  handleClose = e => {
    this.props.history.push(`/`);
  };

  completeForgot = e => {
    e.preventDefault();
    //const that = this;
    const { cookies } = this.props;
    this.setState({ isLoading: true });
    this.setState({ loading: true });
    // Validation
    if (this.state.lkp_email === "" || this.state.lkp_email === undefined) {
      this.setState({ errorMessage: errorNotValidEmail });
    } else {
      this.setState({ isLoading2: true });
      this.setState({ loading2: true });
      this.timer = setTimeout(() => {
        this.setState({ errorMessage: errorNetwork, loading: false });
        // NotificationManager.error("Network Issue", "Login Timeout", 8000);
        this.setState({ loading2: false });
      }, 30000);

      axios
        .post(`${hostApi}/resetPassword`, {
          // KN 2018.12.24 / Reset Password Implementation / Sends email with new password
          email_add: this.state.lkp_email
        })
        .then(response => {
          // PASS SCENARIO
          if (response.data.resetPW_response === "PASS") {
            clearTimeout(this.timer);
            this.setState({
              formTitle: "Please check your Inbox",
              helpMessage: successEmailSent,
              loading: false,
              success: true,
              linkText: "Close"
            });
          } else {
            this.setState({ errorMessage: errorNetwork, loading: false });
            clearTimeout(this.timer);
            this.props.history.push(`/`);
          }
        });
    }
  };

  googleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        console.log("Sign-out successful");
      })
      .catch(function(error) {
        console.log("An error happened.");
      });
  };

  render() {
    const { classes, navigateToMySPRXS, fullScreen } = this.props;
    const {
      isLoading,
      success,
      loading,
      isLoading2,
      success2,
      loading2,
      formTitle,
      helpMessage,
      errorMessage,
      linkText,
      isDisabled,
      focusEmail,
      focusPassword
    } = this.state;

    return (
      <div className="Signup-style app-bg">
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={true}
          fullScreen={fullScreen}
          aria-labelledby="responsive-dialog-title"
        >
          <FormHeader>{formTitle}</FormHeader>
          <DialogContent>
            <Typography variant="body2" className="formHelpMessage">
              {helpMessage}
            </Typography>
            <form className={classes.form}>
              <TextField
                required
                error={this.state.emailValidation}
                id="email1"
                label="Email Address"
                type="email"
                autoComplete="email"
                autoFocus={focusEmail}
                placeholder="e.g. you@email.com"
                className={classes.textField}
                value={this.state.lkp_email}
                onChange={this.handleEmail}
                margin="normal"
                variant="outlined"
                fullWidth={true}
                helperText={
                  this.state.emailValidation
                    ? "*Please enter a valid email address"
                    : ""
                }
              />
              {this.state.forgotPassword === false && (
                <React.Fragment>
                  <TextField
                    required
                    id="outlined-adornment-password"
                    margin="normal"
                    autoFocus={focusPassword}
                    fullWidth={true}
                    className={classes.textField}
                    variant="outlined"
                    type={this.state.showPassword ? "text" : "password"}
                    label="Password"
                    value={this.state.password}
                    onChange={this.handlePassword}
                    onKeyDown={e => {
                      let code = e.keyCode ? e.keyCode : e.which;
                      if (code === 13) {
                        //console.log("ENTER KEY!");
                        this.handleSubmit(e);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <SignupProgressButton
                    loading={loading}
                    success={success}
                    onClick={this.handleSubmit}
                    circle={false}
                    fullwidth={true}
                    btnText="Login"
                    isDisabled={isDisabled}
                  />
                  <Typography variant="body2" className="form_error">
                    {errorMessage}
                  </Typography>
                </React.Fragment>
              )}
              {this.state.forgotPassword === true && (
                <React.Fragment>
                  <Typography variant="body2" className="form_error">
                    {errorMessage}
                  </Typography>
                  <SignupProgressButton
                    loading={loading}
                    success={success}
                    onClick={this.completeForgot}
                    circle={false}
                    fullwidth={true}
                    btnText="Reset Password"
                    btnResponse="Email Sent"
                    isDisabled={isDisabled}
                  />
                </React.Fragment>
              )}
            </form>
            <Button
              onClick={this.handleFbLogin}
              variant="contained"
              color="secondary"
              className={classes.button && classes.facebook}
              navigateToMySPRXS={this.navigateToMySPRXS}
            >
              <FacebookIcon className={classes.leftIcon} />
              LOGIN WITH FACEBOOK
            </Button>
          </DialogContent>
          <DialogActions>
            {this.state.forgotPassword === false && (
              <Button onClick={this.handleForgot} color="primary">
                Forgot Password
              </Button>
            )}
            <Button onClick={this.handleClose} color="primary">
              {linkText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired
};

const mapStateToProps = state => {
  return {
    termsAlert: state.termsAlert
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchAuth: (type, profileID) => {
      dispatchAuth(type, profileID, dispatch);
    },
    dispatchisAuthenticated: (emailAddress = null) =>
      dispatch(isAuthenticated(emailAddress)),
    dispatchFirebaseUid: user => dispatch(dispatchFirebaseUid(user)),
    saveToken: (cookies, token) => saveToken(cookies, token, dispatch)
  };
};

export default withRouter(
  withCookies(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(styles)(gotoMySPRXS(withMobileDialog()(LoginPage))))
  )
);
