import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  closeChatModal,
  setMySprxsTab,
  getCollaborators
} from "../../store/actions/actions";
import Fab from "@material-ui/core/Fab";
import Fade from "@material-ui/core/Fade";
import fire from "../../constants";
import ChatUser from "../../components/ChatMenu/ChatUser";
import ChatMessageFeed from "../ChatMessageDialog/ChatMessageFeed";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AddIcon from "@material-ui/icons/Add";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import "./ChatMain.css";
import "./font.css";
import axios from "axios";
import { hostApi } from "../../constants";
import SwipeableViews from "react-swipeable-views";
import SocialMediaFeed from "../../components/SocialMediaFeed/SocialMediaFeed";
import AllSprxsUsers from "../../components/ChatMenu/AllSprxsUsers";
import { AppBar } from "@material-ui/core";
import _ from "underscore";

// #eaf0fe
const fadeTimeout = 1000;

function TabContainer(props) {
  return (
    <Fade in={true} timeout={500}>
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    </Fade>
  );
}

function Profile(props) {
  const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
  const { firstName, lastName } = window.sessionStorage.getItem("userInfo")
    ? userInfo
    : { email: "", firstName: "", lastName: "" };

  return (
    <div className="user-chat-card">
      <div className="avatar-and-name">
        <div className="sprxs-gender-neutral" />
        <div className="chat-main__user-name">{`${firstName} ${lastName}`}</div>
      </div>
    </div>
  );
}
//
const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    minWidth: "50px"
  },
  paper: {
    padding: 5,
    textAlign: "center",
    justifyContent: "center",
    height: "100%",
    backgroundColor: "#eee"
  },
  userPaper: {
    padding: 5,
    height: "100%"
    /* overflowY: "scroll",  */
  },
  bigAvatar: {
    margin: 10,
    width: 70,
    height: 70
  },
  content: {
    marginTop: theme.spacing.unit * 2
  },
  chip: {
    /* margin: theme.spacing.unit */
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  backButtonLabel: {
    cursor: "pointer"
  },
  smallButton: {
    // width: 40,
    // height: 40
  },
  addButtonWrapper: {
    textAlign: "right",
    padding: "10px 16px"
  }
});

const ChatUserSmall = withStyles(styles, { withTheme: true })(ChatUser);
const AllSprxsUsers2 = withStyles(styles, { withTheme: true })(AllSprxsUsers);
class ChatMain extends React.Component {
  toggleChat = null;
  state = {
    users: [],
    allSprxsUsers: [],
    usersNotUpdated: true,
    emails: [],
    targetChatID: null,
    value: 0,
    currentlySelectedUser: {
      firstName: "",
      lastName: ""
    },
    chatMessageList: [],
    toggleMobileChatCardValue: 1,
    selectedSwipeableTab: 0,
    newChatMessageList: []
  };

  addChatMessage = value => {
    if (this.state && this.state.users && this.state.users.length) {
      const activeList = this.state.users.filter(({ active }) => active);
      if (activeList.length) {
        const active = activeList.reduce(a => a);

        fire
          .database()
          .ref()
          .child("conversations")
          .child(active.location)
          .push(value);
      }
    }
  };
  newChatMessage = value => {
    if (
      this.state &&
      this.state.allSprxsUsers &&
      this.state.allSprxsUsers.length
    ) {
      const activeList = this.state.allSprxsUsers.filter(
        ({ active }) => active
      );
      if (activeList.length) {
        const active = activeList.reduce(a => a);
        // console.log("active", active);
        // fire
        //   .database()
        //   .ref()
        //   .child("conversations")
        //   .child(active.location)
        //   .push(value);
        const messLoc = fire
          .database()
          .ref()
          .child("conversations")
          .push()
          .push(value);
        const messLocKey = messLoc.parent.key;
        let sampleDict = { location: messLocKey };
        fire
          .database()
          .ref()
          .child("users")
          .child(value.fromID) //Current user(Sender)
          .child("conversations")
          .child(active.userId) //Receiver
          .update(sampleDict);

        fire
          .database()
          .ref()
          .child("users")
          .child(active.userId) //Receiver
          .child("conversations")
          .child(value.fromID) //Current User (Sender)
          .update(sampleDict);
      }
    }
  };

  launchChat = value => {
    this.state.users.map(x => x.fire.off("value"));
    value.fire.on("value", snap => {
      const valueOfSnap = snap.val();
      const chatMessageList = Object.keys(valueOfSnap)
        .map(k => valueOfSnap[k])
        .map(x => ({
          timestamp: x.timestamp,
          text: x.content,
          type: x.type,
          outgoing: value.userId !== x.fromID,
          fromID: x.fromID
        }));
      this.setState({
        chatMessageList
      });
    });

    this.setState(state => {
      state.targetChatID = value;

      if (state.users.length > 1) {
        const userList = state.users.map(user => ({
          ...user,
          active: value.userId === user.userId
        }));

        state.users = userList;
        state.currentlySelectedUser = userList
          .filter(user => user.active)
          .reduce(m => m);
      }
      return state;
    });
  };

  launchChatAllUsers = value => {
    this.setState(state => {
      state.targetChatID = value;
      if (state.allSprxsUsers.length > 1) {
        const newUserList = state.allSprxsUsers.map(user => ({
          ...user,
          active: value.userId === user.userId
        }));

        state.allSprxsUsers = newUserList;
        state.currentlySelectedUser = newUserList
          .filter(user => user.active)
          .reduce(m => m);
      }
      return state;
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.startChatWithPerson !== this.props.startChatWithPerson) {
      this.setState(state => {
        state.users = this.state.users.map(user => {
          if (user.userId === nextProps.startChatWithPerson) {
            state.currentlySelectedUser = {
              firstName: user.firstName,
              lastName: user.lastName
            };
            return { ...user, active: true };
          }
          return { ...user, active: false };
        });
        return state;
      });
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.startChatWithPerson !== this.props.startChatWithPerson) {
      this.setState({
        users: this.state.users.map(user =>
          user.userId === this.props.startChatWithPerson
            ? { ...user, active: true }
            : { ...user, active: false }
        )
      });
    }
    this.props.getCollaborators(this.props.cookies.get("bearerToken"));
    document.body.style.overflow = this.props.openChat ? "hidden" : "visible";
  }

  /*
   * FIXME: make this chat fetch functionality it's own component so that we
   * don't duplicate this code in multiple places.
   */
  componentWillUpdate() {
    console.log("ChatMain componentDidMount", this.state, this.props);
    const { startChatWithPerson } = this.props;
    var _users = null;
    var that = this;

    var userListArray = [];
    var allSprxsUsersArray = [];

    if (this.props.uid && this.state.usersNotUpdated) {
      let allusers = fire
        .database()
        .ref()
        .child("users")
        .child(this.props.uid)
        .child("conversations")
        .on("child_added", snapshot => {
          let location = snapshot.val().location;
          let userId = snapshot.key;

          let userCredentials = fire
            .database()
            .ref()
            .child("users")
            .child(userId)
            .child("credentials")
            .once("value")
            .then(snapshot => {
              const snapVal = snapshot.val();
              let { credentials, firstName, lastName, email } = snapVal;

              userListArray.push({
                credentials,
                firstName,
                lastName,
                email,
                userId,
                location,
                fire: fire
                  .database()
                  .ref()
                  .child("conversations")
                  .child(location)
              });
              if (startChatWithPerson) {
                userListArray.map((user, key) => {
                  if (user.userId === startChatWithPerson) {
                    user.active = true;
                    this.launchChat(user);

                    _.debounce(() => {
                      this.setState({
                        currentlySelectedUser: user
                      });
                    }, 500);
                  }
                });
              } else {
                userListArray[0].active = true;
                if (this.state.targetChatID === null) {
                  
                  this.launchChat(userListArray[0]);
                }
                _.debounce(() => {
                  this.setState({
                    currentlySelectedUser: userListArray[0]
                  });
                }, 500);
              }
            });
        });

      fire
        .database()
        .ref()
        .child("users")
        .on("child_added", snapshot => {
          fire
            .database()
            .ref()
            .child("users")
            .child(snapshot.key)
            .on("value", snapshot => {
              console.log("CHAT SNAPSHOT", snapshot);
              let userId = snapshot.key;
              let email = snapshot.val().credentials.email;
              let firstName = snapshot.val().credentials.firstName;
              let lastName = snapshot.val().credentials.lastName;

              allSprxsUsersArray.push({
                userId,
                email,
                firstName,
                lastName
              });
            });

          that.setState({
            users: userListArray,
            usersNotUpdated: false,
            allSprxsUsers: allSprxsUsersArray
          });
        });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log(nextProps, nextState);
    // console.log(
    //   nextState.users,
    //   nextState.allSprxsUsers,
    //   nextState.currentlySelectedUser
    // );
    // console.log(
    //   this.state.users,
    //   this.state.allSprxsUsers,
    //   this.state.currentlySelectedUser
    // );
    // console.log(nextState.user === this.state.users);

    return true;
  }

  toggleMobileChatCard = v => {
    // e.stopPropagation();
    this.setState({ toggleMobileChatCardValue: v });
    // e.nativeEvent.stopImmediatePropagation();
    // e.preventDefault();
  };

  navigateToMyIdeas = () => {
    this.props.handleClose();
    this.props.setMySprxsTab(2);
    this.props.history.push("/home");
  };

  getAllCollaborators = () => {
    const { token } = this.props;
    axios.get(`${hostApi}/getAllCollaborators`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      console.log("All Collab: ", response);
    });
  };

  handleChange = (event, value) => {
    this.setState({ selectedSwipeableTab: value });
    // this.props.setMySprxsTab(value);
    // console.log("Value set:", value);
  };
  handleChangeIndex = index => {
    this.props.setMySprxsTab(index);
    // this.setState({ value: index });
  };

  render() {
    const { classes, openChat, handleClose, theme, cookies } = this.props;
    const {
      users,
      targetChatID,
      value,
      currentlySelectedUser: {
        firstName: selectedFirstName,
        lastName: selectedLastName
      },
      chatMessageList,
      allSprxsUsers,
      newChatMessageList,
      newChatInt,
      newChatMessage,
      selectedSwipeableTab
    } = this.state;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const { email, firstName, lastName } = userInfo
      ? userInfo
      : { email: "", firstName: "", lastName: "" };

    return (
      <div>
        <input
          type="checkbox"
          checked={openChat}
          id="toggle-chat"
          onChange={() => {}}
          style={{
            zIndex: 99999999999999,
            position: "absolute"
          }}
        />
        <div className="chat-modal-wrapper">
          <div className="chat-modal">
            <div className="chat-profile">
              <Profile
                classes={classes}
                firstName={firstName}
                lastName={lastName}
              />
            </div>
            <div className="chat-header">
              <div className="icon-wrapper">
                <div className="sprxs-gender-neutral" />
              </div>

              <div className="chat-main__user-name">
                {`${selectedFirstName} ${selectedLastName}`}
                {/* <div className="one-line-desc">
                  { <span>Java Developer</span> (<b>Idea: </b>
                  <span>#OISDPA</span>) }
                </div> */}
              </div>

              <div className="icon-wrapper">
                <Fab
                  color="primary"
                  size="small"
                  onClick={this.navigateToMyIdeas}
                >
                  <AssignmentTurnedInIcon />
                </Fab>
              </div>
              <div className="icon-wrapper">
                <Fab color="primary" size="small" onClick={handleClose}>
                  <ArrowLeftIcon />
                </Fab>
              </div>
            </div>

            <div className="chat-mobile-menu">
              <label
                htmlFor="toggle-card-1"
                onClick={() => this.toggleMobileChatCard(1)}
                className={`user-chat-mobile-menu${
                  this.state.toggleMobileChatCardValue === 1 ? "-active" : ""
                }`}
              >
                Users
              </label>
              <label
                htmlFor="toggle-card-2"
                onClick={() => this.toggleMobileChatCard(2)}
                className={`user-chat-mobile-menu${
                  this.state.toggleMobileChatCardValue === 2 ? "-active" : ""
                }`}
              >
                Chat
              </label>
            </div>

            <input
              id="toggle-card-1"
              type="radio"
              name="toggle-card-group"
              onChange={() => {}}
              checked={this.state.toggleMobileChatCardValue === 1}
            />

            <div className="chat-sidebar">
              <Tabs
                value={this.props.selectedSparkTab}
                onChange={this.handleChange}
                fullWidth
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab className={classes.tabSelect} label={"INBOX"} />
                <Tab className={classes.tabSelect} label={"ALL SPRXS USERS"} />
              </Tabs>

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={this.state.selectedSwipeableTab}
                onChangeIndex={this.handleChangeIndex}
              >
                <TabContainer dir={theme.direction}>
                  {users.length ? (
                    <ChatUserSmall
                      userList={users}
                      launchChat={this.launchChat}
                    />
                  ) : (
                    <Typography>
                      Hey, you don't have any friends yet!
                    </Typography>
                  )}
                </TabContainer>
                <TabContainer dir={theme.direction}>
                  {allSprxsUsers.length ? (
                    <AllSprxsUsers2
                      userList={allSprxsUsers}
                      launchChat={this.launchChatAllUsers}
                    />
                  ) : (
                    <Typography>
                      Unable to get the requested data. Please check your
                      internet connection or refresh the page.
                    </Typography>
                  )}
                </TabContainer>
              </SwipeableViews>
            </div>

            <input
              id="toggle-card-2"
              type="radio"
              name="toggle-card-group"
              onChange={() => {}}
              checked={this.state.toggleMobileChatCardValue === 2}
            />

            <div className="chat-message-list">
              {targetChatID && selectedSwipeableTab ? (
                <ChatMessageFeed
                  chatID={targetChatID}
                  addChatMessage={this.newChatMessage}
                  chatMessageList={newChatMessageList}
                />
              ) : (
                <ChatMessageFeed
                  chatID={targetChatID}
                  addChatMessage={this.addChatMessage}
                  chatMessageList={chatMessageList}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChatMain.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    collaboratorsByIdea: state.collaboratorsByIdea,
    openChat: state.showChatModal,
    startChatWithPerson: state.startChatWithPerson,
    uid: state.firebaseUid,
    token: state.jwtToken
  };
};

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeChatModal()),
  getCollaborators: (jwtToken) => dispatch(getCollaborators(jwtToken)),
  setMySprxsTab: payload => dispatch(setMySprxsTab(payload))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(ChatMain))
);
